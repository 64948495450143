//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    AuditTable: () => import("@/userview/apply-audit"),
  },
  props: {
    //团队
    selRow: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      subLoading: false,
      showError: false,
      // 考勤
      customForm: {
        AnomalyTime: new Date().timeFormat("yyyy-MM-dd"), //异常日期
        AnomalyType: [], //1上班/2下班
        ClockInTime: null, //上班时间
        ClockInStatus: null, //上班状态
        ClockOutTime: null, //下班时间
        ClockOutStatus: null, //下班状态
        Reason: null, //原因
      },
      customRules: {
        Reason: [{ required: true, message: "请输入申诉理由" }],
      },
      type: 1,
      loadingForm: false,
    };
  },
  watch: {
    // "customForm.AnomalyTime": {
    //   handler(newval) {
    //     this.$http
    //       .get("/Work/Attendance/GetAttendanceByDate.ashx", {
    //         params: { date: newval },
    //       })
    //       .then((resp) => {
    //         if (resp.data && resp.data.ArId) {
    //           this.showError = false;
    //           this.customForm.fromRecordID = resp.data.ArId;
    //         } else {
    //           this.showError = true;
    //         }
    //       });
    //   },
    //   immediate: true,
    // },
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.subLoading = true;
          this.customForm.teamId = this.selRow.Id;
          this.$http
            .post(
              "/Attendance/AnomalyAppeals/SaveAnomalyAppeal.ashx",
              this.customForm
            )
            .then((resp) => {
              if (resp.res == 0) {
                this.$notify({
                  message: resp.msg,
                  type: "success",
                });
                Object.keys(this.customForm).forEach((m) => {
                  if (m == "AnomalyTime") {
                    this.customForm[m] = new Date().timeFormat("yyyy-MM-dd");
                  } else if (m == "AnomalyType") {
                    this.customForm[m] = [];
                  } else {
                    this.customForm[m] = null;
                  }
                });
                this.$refs.table.initData();
              }
            })
            .finally(() => (this.subLoading = false));
        } else {
          return false;
        }
      });
    },
    filterOption(item) {
      return item.value == -1 || item.value == 3;
    },
    upTable() {
      this.$refs.table.loadData();
    },
  },
};
